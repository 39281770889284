'use client'

import React, { useState, useEffect } from "react"
import { Brain, Dumbbell, ThumbsDown, Tornado } from "lucide-react"

const contentPlaceholders = {
    title: "Stop Yapping",
    subtitle: "You've heard of nohello, but have you also come across a stream of words only to find they don't add any meaning? The ones that seem to be written by a bot and just echo noise for the sake of engagement? Imagine an amazing internet without having to deal with those",
    whyItMatters: "Why Not?",
    dilutesMessage: "Harder to discover useful content",
    timeWaste: "Internet entropy++",
    clearCommunication: "First practice what you preach",
    improveQuality: "Accountability--",
    apiButtonText: "Get a random trivia instead",
    apiButtonLoadingText: "Fetching Trivia...",
    apiErrorMessage: "Failed to fetch trivia. Please try again later.",
}

export default function NoYappingSPA() {
    const [content, setContent] = useState(contentPlaceholders)
    const [isLoading, setIsLoading] = useState(false)
    const [localApiData, setLocalApiData] = useState("")

    useEffect(() => {
        setContent(contentPlaceholders)
    }, [])

    const handleLocalApiCall = async () => {
        setIsLoading(true)
        var api_url = String(process.env.REACT_APP_API_URL)

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 3000); // Abort after 3 seconds

        try {
            const response = await fetch(api_url, {
                method: 'GET',
                credentials: 'include',
                signal: controller.signal }
            )
            if (!response.ok) {
                throw new Error("Local API call failed")
            }
            const data = await response.text()
            setLocalApiData(data)
        } catch (error: unknown) {
            if ((error as Error).name === 'AbortError') {
                console.error("API call error: Request timed out");
                setLocalApiData("Your matrix coordinates are 11.5, 22.6, 98.3");
            } else {
                console.error("API call error:", error);
                setLocalApiData("Your matrix coordinates are 11.5, 22.6, 98.3");
            }
        } finally {
            clearTimeout(timeoutId);
            setIsLoading(false)
        }
    }

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 flex flex-col justify-center">
            <div className="w-full max-w-3xl mx-auto space-y-6">
                <div className="text-center">
                    <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-2">{content.title}</h1>
                    <p className="text-lg sm:text-xl text-gray-600">{content.subtitle}</p>
                </div>

                <div className="bg-white shadow rounded-lg p-4 sm:p-6 space-y-6">
                    <h2 className="text-xl sm:text-2xl font-semibold text-gray-900">{content.whyItMatters}</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="flex items-start space-x-3">
                            <Brain className="w-6 h-6 text-pink-500 flex-shrink-0 mt-1" />
                            <p className="text-sm sm:text-base text-gray-700">{content.dilutesMessage}</p>
                        </div>
                        <div className="flex items-start space-x-3">
                            <Tornado className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1" />
                            <p className="text-sm sm:text-base text-gray-700">{content.timeWaste}</p>
                        </div>
                        <div className="flex items-start space-x-3">
                            <Dumbbell className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
                            <p className="text-sm sm:text-base text-gray-700">{content.clearCommunication}</p>
                        </div>
                        <div className="flex items-start space-x-3">
                            <ThumbsDown className="w-6 h-6 text-purple-500 flex-shrink-0 mt-1" />
                            <p className="text-sm sm:text-base text-gray-700">{content.improveQuality}</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white shadow rounded-lg p-4 sm:p-6 flex flex-col items-center">
                    <img
                        src="/95e.webp"
                        alt="Crow of Judgement"
                        className="w-48 h-48 sm:w-64 sm:h-64 object-cover square-full mb-4"
                    />
                    <p className="text-center text-gray-600 text-xs sm:text-sm">
                        The crow is always watching, judging unnecessary yapping.
                    </p>
                </div>

                <div className="bg-white shadow rounded-lg p-4 sm:p-6 space-y-4">
                    <button
                        onClick={handleLocalApiCall}
                        disabled={isLoading}
                        className="w-full bg-indigo-600 hover:bg-indigo-500 text-white font-medium py-2 px-4 rounded transition-colors duration-200"
                    >
                        {isLoading ? content.apiButtonLoadingText : content.apiButtonText}
                    </button>

                    {localApiData && (
                        <div className="mt-4 p-4 bg-gray-100 rounded-lg">
                            <strong className="block mb-2 text-base sm:text-lg">Very interesting trivia:</strong>
                            <p className="text-sm sm:text-base text-gray-700">{localApiData}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}